@import "../../styles/config.scss";

.menu-items {
    width: 100%;
    display: flex;
    max-width: 519px;
    justify-content: space-between;
    list-style: none;
    align-items: center;

    &__link {
        @include text;
        color: $color-gray;
        text-align: left;
        cursor: pointer;

        &--active {
            color: inherit;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1439px) {
        &__link:not(:first-child) {
            margin-left: 11px;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1439px) {
        &__link:not(:first-child) {
            margin-left: 15px;
        }

        &__link {
            position: relative;
            left: 37px;
            z-index: 100;
        }
    }

    @media screen and (min-width: 1440px) {
        &__link:not(:first-child) {
            margin-left: 48px;
        }
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;

        &__link:not(:first-child) {
            padding-top: 24px;
        }

        &__link {
            font-size: 20px;
            font-weight: 500;
        }
    }
}
