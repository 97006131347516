@import "../../styles/config.scss";
@import "../../styles/fonts.scss";

.intro {
    &__wrapper {
        width: 100%;
        max-width: unset;
        background-image: url("../../assets/images/introbg.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    & {
        width: 100%;
        max-width: 1155px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 1024px) {
            z-index: 90;
        }
    }

    &__store-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 35px;

        @media (max-width: 1024px) {
            z-index: 11;
            position: relative;
        }
    }

    &__headings {
        display: flex;
        flex-direction: column;
        max-width: 539px;
        margin-top: 106px;
    }

    &__title {
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        letter-spacing: -0.5px;
        text-align: center;
        color: $color-green;
    }

    &__subtitle {
        margin-top: 24px;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.5px;
        text-align: center;
        color: $color-DarkCharcoal;
    }

    &__text {
        background-color: $color-white;
        border-radius: 28px;
        padding: 10px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -42px;
    }

    &__icon {
        margin: 62px 0px -10px 0px;

        &--blue {
            margin-top: 62px;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        gap: 201px;
        margin-top: 89px;
        margin-left: -150px;
    }

    &__tower-icon {
        margin-top: -30px;
    }

    &__icon-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon-inner {
        margin-left: -30px;
        margin-top: -10px;
    }

    &__image {
        width: 100%;
        height: 100%;
        top: 71px;
        position: absolute;
        transform: scale(1.6);
        z-index: 1;

        &--tower {
            // дополнительные стили для изображений башни
        }
    }

    &__roadmap-big {
        position: absolute;
        top: 248px;
        right: 122px;
        transform: scale(101%) rotate(1deg);
    }

    &__roadmap-small {
        position: absolute;
        top: 207px;
        left: 127px;
        transform: scale(96%);
    }

    &__new {
        display: none;
    }

    &__bike-mobile {
        display: none;
    }

    &__bike-desktop {
        display: block;
        width: 175px;
        height: 130px;
        margin-top: 8px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1439px) {
        &__wrapper {
            position: relative;
            max-width: 978px;
            margin: 0 auto;
        }

        & {
            width: 100%;
        }

        &__roadmap-big {
            position: absolute;
            top: 180px !important;
            right: -100px !important;
            transform: scale(78%) !important;
        }

        &__tower-icon {
            right: -90px;
            top: -42px;
            position: relative;
        }

        &__roadmap-small {
            position: absolute;
            top: 147px;
            left: 0px;
            transform: scale(84%) rotate(-4deg);
        }

        &__text--tower-two {
            position: relative;
            right: -90px;
            top: -32px;
            background-color: $color-white;
            z-index: 101;
        }

        &__image--tower {
            margin-right: -200px;
        }

        &__bike-desktop {
            margin-top: -70px;
        }

        &__text {
            z-index: 101;
            position: relative;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        & {
            width: 90%;
        }
    }

    @media screen and (max-width: 1023px) {
        &__roadmap-big {
            display: none;
        }

        &__roadmap-small {
            display: none;
        }

        & {
            flex-direction: column;
        }

        &__content {
            display: none;
        }

        &__inner {
            position: absolute;
            top: 400px;
            left: 20px;
        }

        &__title {
            font-size: 40px;
            font-weight: bold;
        }

        &__store-buttons {
            flex-direction: column;
        }

        &__icon-wrapper {
            display: none;
        }

        &__inner {
            display: none;
        }

        &__icon-wrapper-mobile {
            display: none;
        }

        &__text {
            display: none;
        }

        &__icon {
            display: none;
        }

        &__bike-mobile {
            position: relative;
            top: 100px;
            display: block;
        }

        &__new {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            align-items: center;
        }

        &__new-icon-inner {
            display: flex;
            align-items: center;
        }

        &__new-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            padding: 10px;
            margin-bottom: 10px;
        }

        &__new-image {
            position: absolute;
            width: 16px;
            transform: scale(37.3);
        }

        &__new-icon {
            width: 111px;
            height: 111px;
            z-index: 10;
        }

        &__new-text {
            @include text;
            background-color: $color-white;
            border-radius: 20px;
            padding: 10px 16px;
            position: relative;
            left: -44px;
            top: 10px;
        }

        &__new-icon-inner--tower {
            display: flex;
            flex-direction: row-reverse;
        }

        &__new-text--tower {
            @include text;
            background-color: $color-white;
            border-radius: 28px;
            padding: 10px 16px;
            position: relative;
            top: 17px;
            z-index: 2;
        }

        &__new-icon-group {
            margin-top: 20px;
            width: 100%;
            max-width: 370px;
            min-width: 370px;
            position: relative;
        }

        &__new-icon-group > * {
            margin-top: -10px;
        }

        &__roadmap-big-mobile {
            position: absolute;
            top: 212px;
            left: 92px;
            z-index: 1;
        }

        &__roadmap-small-mobile {
            position: relative;
            top: 162px;
            left: 56px;
            transform: scale(104%);
            z-index: 1;
        }
    }
}
