@import "../../styles/config.scss";

.footer {
    width: 100%;
    height: 330px;
    background-color: $color-DarkCharcoal;

    &__socials {
        display: flex;
        align-items: center;
        margin-top: 24px;
        gap: 12px;

        &-button {
            cursor: pointer;
        }
    }

    &__inner {
        padding: 48px 100px 0 100px;
        display: flex;
        justify-content: space-between;

        @media screen and (min-width: 768px) and (max-width: 1439px) {
            padding: 30px 50px 0 40px;
        }

        @media screen and (max-width: 1023px) {
            flex-direction: column;
            padding: 40px 20px;
        }
    }

    &__address {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        max-width: 420px;

        &-text {
            color: #7d7d7d;
            text-align: left;
            margin-top: 12px;
        }
    }

    &__logo {
        margin-bottom: 20px;
    }

    &__email,
    &__company-name,
    &__address-text {
        color: #7d7d7d;
        text-align: left;
        margin-top: 12px;
    }

    &__right {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1023px) {
            align-items: start;
            text-align: left;
        }
    }

    &__menu-items {
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;

        @media screen and (max-width: 1023px) {
            margin-top: 40px;
            padding-right: 0;
            text-align: left;

            ul {
                text-align: left;
                align-items: start;
                margin-left: 0;
            }
        }
    }

    &__store-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 81px;

        @media screen and (max-width: 1023px) {
            width: 100%;
            max-width: 350px;
            margin: 28px auto;
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 1023px) {
    .footer {
        margin-top: -37px;
        height: auto;
        z-index: 1;
        position: relative;
    }
}
