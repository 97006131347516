/*
=============================
Colors
=============================
*/

$color-white: #ffffff;
$color-gray: #888888;
$color-green: #28a889;
$color-silver: #f3f3f3;
$color-darkSilver: #e5e5e5;
$color-DarkCharcoal: #2f2f2f;
$color-purple: rgba(118, 34, 127, 1);

/*
=============================
Text
=============================
*/

@mixin text-small {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0%;
}

@mixin text {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.5px;
}

@mixin text-medium {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0%;
}

@mixin text-large {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
    letter-spacing: -0.5px;
    text-align: center;
    @media screen and (max-width: 767px) {
        font-size: 32px;
        font-weight: 500;
    }
}

/*
=============================
Mixins
=============================
*/

@mixin _desktop_big {
    @media (max-width: 1280px) {
        @content;
    }
}

@mixin _desktop {
    @media (max-width: 1170px) {
        @content;
    }
}

@mixin _tablet {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin _mobile {
    @media (max-width: 450px) {
        @content;
    }
}

@mixin _land {
    @media (orientation: landscape) {
        @content;
    }
}
