@import 'config';
/*
=============================
Reset
=============================
*/

@import 'reset';

/*
=============================
Typography
=============================
*/

@import 'fonts';

/*
=============================
Body
=============================
*/

* {
    box-sizing: border-box;
}

html,
body,
#root,
#root>div {
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 1.4;
    background-color: #f2f4f6;
}

input,
button {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 16px;
}

a {
    color: $color-purple;
    text-decoration: none;
}

.container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 38px 100px 0px 100px;
    position: relative;

    @media screen and (min-width: 1024px) and (max-width: 1439px) {
        padding: 20px;
    }

    @media screen and (max-width: 1023px) {
        padding: 0px;
    }
}

.leaflet-popup-content-wrapper {
    max-width: 92% !important;
}

.App {
    background-color: #fff;
    height: auto;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .container {
        padding: 20px;
    }
}