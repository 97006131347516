@import "../../styles/config.scss";

.contact {
    &__wrapper {
        // max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 64px 120px;
        background-color: $color-green;

        @media screen and (max-width: 1023px) {
            flex-direction: column;
            padding: 20px;
            border-radius: 40px;
            z-index: 4;
            position: relative;
        }
    }

    &__text-section {
        flex: 1;
        padding: 20px;
        color: white;
        max-width: 534px;

        @media screen and (max-width: 1023px) {
            text-align: center;
        }
    }

    &__title {
        @include text-large;
        text-align: left;

        @media screen and (max-width: 1023px) {
            text-align: center;
            font-weight: 600;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    &__subtitle {
        @include text;
        margin-top: 24px;

        @media screen and (max-width: 1023px) {
            text-align: center;
            max-width: 400px;
            margin: 24px auto;
        }
    }

    &__form-section {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        @media (min-width: 1440px) {
            width: 56%;
        }

        @media screen and (max-width: 1023px) {
            width: 100%;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        @media screen and (max-width: 1023px) {
            margin: 0 auto;
            max-width: 500px;
        }
    }

    &__input-group {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;

        label {
            @include text;
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: 400;
            color: $color-white;
        }

        input,
        textarea {
            padding: 10px;
            border-radius: 5px;
            border: none;
            outline: none;
            background-color: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
        }

        input::placeholder,
        textarea::placeholder {
            @include text;
            font-size: 14px;
            color: $color-white;
        }
    }

    &__textarea {
        height: 155px;
    }

    &__submit-button {
        @include text-medium;
        max-width: 157px;
        padding: 10px 16px;
        border-radius: 20px;
        border: none;
        background-color: $color-white;
        color: $color-green;
        cursor: pointer;
        transition: background-color 0.3s ease;

        @media screen and (max-width: 1023px) {
            max-width: 100%;
        }
    }

    &__icon {
        margin-top: 24px;

        img {
            max-width: 100px;
            max-height: 100px;
        }

        @media screen and (min-width: 1024px) and (max-width: 1439px) {
            margin-left: 50px;
        }

        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
}
