@import "../../styles/config.scss";

.caring-of-enviroment {
    &__inner {
        margin-top: 100px;
    }

    &__text {
        width: 60%;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 0 auto;
    }

    &__leaf-icon {
        margin-right: 4px;
        margin-bottom: -3px;
    }

    &__title {
        @include text-large;
        color: $color-green;
    }

    &__subtitle {
        @include text;
        color: $color-DarkCharcoal;
        margin-top: 24px;
    }

    &__icons {
        display: flex;
        justify-content: center;
        margin-top: 78px;
        padding: 0 50px;

        gap: 140px;
        svg {
            scale: calc(1.8);
        }

        @media screen and (max-width: 1023px) {
            gap: 100px;
            svg {
                scale: calc(1.5);
            }
        }

        @media screen and (max-width: 700px) {
            gap: 15vw;
        }

        @media screen and (max-width: 500px) {
            padding: 0 40px;
            gap: 12vw;
        }
    }

    &__line {
        align-self: flex-end;
        margin-right: 115px;
    }

    @media screen and (max-width: 1023px) {
        &__text {
            width: 100%;
            padding: 14px;
        }

        &__icons {
            margin-top: 0;
        }

        &__line {
            align-self: center;
            margin-right: 0;
        }

        &__subtitle {
            max-width: 700px;
            margin: 24px auto;
        }
    }
}
