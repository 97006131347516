@import "../../styles/config.scss";

.application {
    &__inner {
        width: 100%;
        margin-top: 140px;
        text-align: center;
    }

    &__title {
        @include text-large;
        color: $color-DarkCharcoal;
    }

    &__navlinks {
        display: flex;
        justify-content: center;
        margin-top: 48px;
    }

    &__navlink {
        background-color: #f3f3f3;
        padding: 10px 16px;
        border-radius: 28px;
        transition: background-color 0.3s;
        cursor: pointer;

        &--active {
            background-color: #f3f3f3;
        }

        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    &__link-text {
        @include text-medium;
        font-weight: 400;
    }

    &__content-wrapper {
        margin-top: 24px;
        border-radius: 40px;
        transition: background-color 0.3s;

        &--map-background {
            background: #d4eee7;
        }

        &--turistic-background {
            background: #ffdfd4;
        }

        &--rental-background {
            background: #dfe6ff;
        }
    }

    &__content {
        width: 100%;
        height: 529px;
        border-radius: 40px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        padding: 60px 0 24px 60px;
    }

    &__headings {
        display: flex;
        flex-direction: column;
    }

    &__content-title {
        @include text-large;
        box-sizing: border-box;
        text-align: left;
    }

    &__subtitle {
        @include text;
        max-width: 70%;
        text-align: left;
        margin-top: 24px;
    }

    &__image {
        position: relative;
        right: 0;
        top: -37px;

        &--tree {
            position: relative;
            top: -60px;
        }
    }

    &__info-content {
        display: flex;
        margin-top: 54px;
        position: relative;
    }

    &__info-content-inner {
        & > div:last-child {
            margin-top: 40px;
            justify-content: center;
            transform: rotate(7.31deg);
            position: relative;
            left: 105px;
        }

        & > div:first-child {
            transform: rotate(-5deg);
        }
    }

    &__info-item {
        padding: 10px 16px;
        background-color: $color-white;
        border-radius: 40px;
        z-index: 3;
        white-space: nowrap;
    }

    &__info-item-right {
        margin-left: 47px;
        transform: rotate(8.36deg);
    }

    &__info-item-text {
        @include text;
        color: $color-DarkCharcoal;
    }

    &__roadmap {
        position: absolute;
        right: 10px;
        margin-top: 7px;
        left: 90px;
        z-index: 1;
    }

    &__icons {
        margin-top: 96px;
        display: flex;
    }

    &__roadmap-mobile {
        display: none;
    }

    &__dots {
        display: flex !important;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 10px 0 0;
        background: transparent;
        color: transparent;

        li {
            display: inline-block;
            margin: 0px 5px;

            button {
                border: 0;
                background: transparent;
                color: transparent;
                cursor: pointer;
                padding: 5px;
                margin-top: 20px;

                &::before {
                    content: "";
                    display: block;
                    color: transparent !important;
                    width: 12px;
                    height: 12px;
                    background-color: $color-green;
                    border-radius: 50%;
                    transition: background-color 0.3s ease;
                    margin-top: 10px !important;
                }
            }

            &.slick-active button::before {
                background-color: $color-green;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        &__navlinks {
            display: none;
        }

        &__content {
            flex-direction: column;
            height: auto;
            padding: 20px;
        }

        &__subtitle {
            min-width: 100%;
        }

        &__content-wrapper {
            margin: 32px auto;
            max-width: 700px;
        }

        &__info-item-right {
            margin-left: -150px;
            z-index: 1;
        }

        &__info-content {
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
        }

        &__roadmap {
            display: none;
        }

        &__image {
            top: unset;
            bottom: -22px;
        }

        &__image img {
            width: 100%;
            max-width: 500px;
        }

        &__icons {
            margin-top: 0;
        }

        &__info-content-inner {
            & > div:last-child {
                left: unset;
                margin-top: 10px;
            }

            & > div:first-child {
                position: relative;
                right: -61px;
            }
        }

        &__roadmap-mobile {
            display: block;
            position: absolute;
            margin-top: 28px;
        }

        &__inner {
            width: 90%;
            margin: 60px auto;
        }

        &__dots {
            width: 8px;
            height: 8px;
            display: block !important;

            li {
                margin: 0 5px;
                font-family: unset !important;

                button {
                    padding: 0;
                }
            }

            button::before {
                content: "";
                display: inline-block;
                border-radius: 50%;
                background: $color-green;
                font-size: 0;
                font-family: unset !important;
            }

            li.slick-active button:before {
                width: 20px;
                height: 20px;
                background-color: $color-green;
            }
        }
    }
}
