@import "../../styles/config.scss";

.our-bikes {
    &__inner {
        margin-top: 140px;
        text-align: center;
    }

    &__title {
        @include text-large;
    }

    &__modal-title {
        @include text-medium;
        font-size: 24px;
        text-align: center;
        font-weight: 500;
    }

    &__content {
        display: flex;
    }

    &__item {
        background-color: $color-silver;
        border-radius: 20px;
        margin: 32px;
    }

    &__icon {
        margin-top: 28px;
    }

    &__description {
        @include text;
        text-align: left;
        display: flex;
        flex-direction: column;
        padding: 48px 48px 0px 48px;
    }

    &__button {
        @include text-medium;
        padding: 10px 16px 10px 16px;
        background-color: $color-green;
        border-radius: 28px;
        border: none;
        color: $color-white;
        display: flex;
        justify-content: flex-start;
        margin: 48px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1439px) {
        &__item {
            margin: 32px 12px;
        }
    }

    @media screen and (max-width: 1023px) {
        &__content {
            display: flex;
            flex-direction: column;
            max-width: 700px;
            margin: 0 auto;
        }

        &__item {
            margin: 32px 20px;
        }

        &__icon {
            max-width: 290px;
        }

        &__description {
            padding: 30px 30px 0px 30px;
        }

        &__button {
            text-align: center;
            margin: 30px auto;
            width: 86%;
            justify-content: center;
        }
    }
}
