@import "../../styles/config.scss";

.form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    &__input-group {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
    }

    &__label {
        margin-bottom: 5px;
        margin-top: 24px;
        color: $color-DarkCharcoal;
    }

    &__input {
        @include text;
        font-size: 14px;
        padding: 10px;
        border-radius: 7px;
        background-color: $color-silver;
        border: none;
    }

    &__submit-button {
        @include text;
        padding: 10px 20px;
        margin-top: 28px;
        border-radius: 28px;
        border: none;
        background-color: $color-green;
        color: $color-white;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    &__error {
        color: red;
        font-size: 12px;
        margin-top: 5px;
    }
}
