@import "../../styles/config.scss";

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__content {
    background: $color-white;
    padding: 48px 53px;
    border-radius: 40px;
    max-width: 404px;
    width: 100%;
    position: relative;
}

.modal__close-button {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 2.2em;
    cursor: pointer;
    background-color: $color-silver;
    font-weight: 400;
}

.modal__title {
    @include text-medium;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
}
