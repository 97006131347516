@import "../../styles/config.scss";

.header {
    width: 100%;
    padding: 20px 20px 0 20px;
    position: relative;

    @media (min-width: 768px) {
        padding: 38px 100px 0 100px;
    }

    &__logo {
        max-width: 30px;
    }

    &__navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background-color: white;
    }

    &__menu-toggle {
        display: flex;
        cursor: pointer;
        z-index: 1100;

        @media (min-width: 768px) {
            display: none;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__menu-items {
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateY(-100%);
            transition: transform 0.3s ease-in-out;
            overflow-y: auto;
            padding: 20px;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
            z-index: 1000;
        }

        &--show {
            transform: translateY(0);
            border-top: 1px solid $color-darkSilver;
            z-index: -1;

            @media (min-width: 768px) {
                transform: none;
            }
        }

        &-inner {
            display: flex;

            @media (max-width: 767px) {
                margin-top: 89px;
                width: 100%;
                max-width: 350px;
                flex-direction: column;
            }
        }
    }

    &__language {
        border: 1px solid rgb(229, 229, 229);
        border-radius: 10px;
        width: 68px;
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        z-index: 100;
    }

    &__dropdown {
        position: absolute;
        top: -1px;
        right: -2px;
        background: #ffffff;
        border: 1px solid rgb(229, 229, 229);
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 70px;
        z-index: 1000;

        &-item {
            display: flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: #f3f3f3;
            }
        }
    }

    &__language-text {
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.5px;
        text-align: left;
    }

    &__menu-overlay {
        display: none;

        @media (max-width: 767px) {
            display: block;
            // position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background: rgba(0, 0, 0, 0.5);
            // z-index: 999;
        }
    }

    &__ellipse {
        display: none;

        @media (max-width: 767px) {
            position: absolute;
            top: -30px;
            margin-left: -40px;
            display: block;
            z-index: -1;
        }
    }

    &__store-buttons {
        display: none;

        @media (max-width: 767px) {
            display: block;
        }
    }

    &__menu-desktop {
        display: block;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__menu-mobile {
        display: none;

        @media (max-width: 767px) {
            display: block;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        & {
            padding: 20px 20px 0 20px;
        }
    }

    @media screen and (max-width: 767px) {
        & {
            background: $color-white;
            // position: fixed;
            top: -16px;
            z-index: 12;
        }

        &__navbar {
            z-index: 10;
            border-bottom: 1px solid $color-darkSilver;
            height: 67px;
            position: initial;
        }

        &__language {
            margin: 48px auto;
        }
    }
}
