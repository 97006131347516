@import "../../styles/config.scss";

.store-button {
    display: flex;
    align-items: center;
    border: 1px solid $color-gray;
    cursor: pointer;
    width: 199.67px;
    height: 62px;
    border-radius: 8px;
    margin-right: 12px;

    &__icon {
        margin-left: 23px;
        margin-right: 6px;
    }

    &__text {
        display: flex;
        flex-direction: column;
    }

    &__title {
        @include text-small;
        color: $color-white;
        text-align: left;
    }

    &__subtitle {
        @include text-medium;
        color: $color-white;
    }

    @media screen and (max-width: 1023px) {
        width: 100%;
        max-width: 100%;
        margin-top: 12px;
    }
}
