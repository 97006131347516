@import "../../styles/config.scss";

.about-us {
    &__inner {
        margin-top: 140px;
        text-align: center;
    }

    &__title {
        @include text-large;
        color: $color-DarkCharcoal;
        margin-bottom: 40px;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 47px;
        align-items: center;
    }

    &__item {
        width: 100%;
        max-width: 392px;
        height: 442px;
        position: relative;
        text-align: center;
        border-radius: 10px;
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        &-inner {
            text-align: left;
            margin-top: 32px;
        }

        &::not(:first-child) {
            margin-left: 32px;
        }
    }

    &__phone-icon {
        position: absolute;
        //top: 10px;
        // transform: scale(90%);
        display: block;
    }

    &__bike-icon {
        position: absolute;
        top: -30px;
    }

    &__safety-icon {
        position: absolute;
        bottom: 0;
    }

    &__safety-image-mobile {
        display: none;
    }

    &__background-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
    }

    &__subtitle {
        width: 100%;
        @include text;
        font-size: 24px;
        font-weight: 500;
        text-align: left;
        padding: 32px 0 0 32px;

        &--one {
            width: 100%;
            @include text;
            font-size: 24px;
            font-weight: 500;
            text-align: left;
            padding: 32px 0 0 32px;
        }
    }

    &__description {
        @include text;
        text-align: left;
        padding: 18px 32px 32px 32px;
    }

    &__safety-icon-mobile,
    &__bike-icon-mobile,
    &__bike-icon-mobile-bike,
    &__phone-icon-mobile {
        display: none;
    }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
    .about-us__subtitle--one {
        top: 323px !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 370px) {
    .about-us__subtitle--one {
        top: 293px !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .about-us__item:not(:first-child) {
        margin: 12px;
    }
    .about-us__item {
        height: 480px;
    }
    .about-us__safety-icon {
        max-width: 100%;
        bottom: -22px;
    }
    .about-us__bike-icon {
        max-width: 90%;
    }
    .about-us__phone-icon {
        max-width: 90%;
    }
}

@media screen and (max-width: 1023px) {
    .about-us__content {
        flex-direction: column;
    }

    .about-us__content .about-us__item:first-child {
        border-top-right-radius: 40px !important;
        border-top-left-radius: 40px !important;
    }

    .about-us__content .about-us__item:nth-child(2) {
        height: 430px;
    }

    .about-us__content .about-us__item:last-child {
        border-bottom-right-radius: 40px !important;
        border-bottom-left-radius: 40px !important;
        height: auto;
        border-top: none;
    }

    .about-us__item {
        margin-top: -3px;
        border-top: 1px solid $color-darkSilver;
        width: 100%;
        max-width: 700px;
        height: 540px;
        border-radius: 0px;
        align-items: center;
        text-align: center;
        overflow: hidden;

        &::not(:first-child) {
            margin-left: 0px;
        }
    }

    .about-us__bike-icon {
        display: none;
    }

    .about-us__bike-icon-mobile {
        display: block;
        transform: scale(2);

        &-bike {
            display: block;
            margin-top: 40px;
            transform: scale(1);
        }
    }

    .about-us__subtitle {
        text-align: center;
        padding: 0;
        align-items: center;

        &--one {
            position: relative;
            top: 300px;
            justify-content: center;
            display: flex;
            width: 100%;
            @include text;
            font-size: 24px;
            font-weight: 500;
            text-align: left;
            padding: 0px;
        }
    }

    .about-us__description {
        text-align: center;

        @media (max-width: 354px) {
            padding: 18px 12px 12px 12px;
        }
    }

    .about-us__safety-icon {
        display: none;
    }

    .about-us__safety-icon-mobile {
        transform: scale(1);
        display: block;
    }

    .about-us__safety-image-mobile {
        display: block;
        width: 100%;
    }

    .about-us__item-inner {
        text-align: center;
    }

    .about-us__phone-icon {
        position: absolute;
        top: 20px;
    }
}
